<template>
  <ConfigPage>
    <ConfigItem
      :title="__('Categorias de anexo', 'settings')"
      :description="
        __(
          'Mapeia as categorias do Portal HCM para as categorias da EPS',
          'settings'
        )
      "
    >
      <v-card outlined class="mb-12">
        <v-simple-table>
          <thead>
            <tr>
              <th>Categoria HCM</th>
              <th>Categoria EPS</th>
              <th>Subcategoria EPS</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in items" :key="item.id">
              <td>
                <a @click="openFormFilled(item)">
                  {{ item.category_description || "*" }}
                </a>
              </td>
              <td v-text="item.category_value"></td>
              <td v-text="item.subcategory_value"></td>
              <td>
                <v-btn @click="handleDelete(item)" icon small color="red">
                  <v-icon small>mdi-close</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </v-simple-table>

        <v-card-actions>
          <v-btn @click="openForm()" text color="primary" small class="mt-2">
            <v-icon left>mdi-plus</v-icon> Novo mapeamento
          </v-btn>
        </v-card-actions>
      </v-card>
    </ConfigItem>

    <EpsAttachmentCategoryForm
      ref="form"
      @submit="handleSubmit"
      title="Categoria de Anexo"
      :categories="categories"
    />
  </ConfigPage>
</template>

<script>
import ConfigPage from "@/layouts/configs/ConfigPage";
import ConfigItem from "@/layouts/configs/ConfigItem";
import EpsAttachmentCategoryForm from "@/components/EPS/EpsAttachmentCategoryForm.vue";
import i18n from "@/mixins/i18n";
import { indexManagementCategories } from "@/services/config/attachment_categories";
import {
  storeOrUpdateAttachmentCategories as store,
  destroyAttachmentCategories as destroy,
  indexAttachmentCategories as index,
} from "@/services/eps.js";

export default {
  components: {
    ConfigPage,
    ConfigItem,
    EpsAttachmentCategoryForm,
  },

  mixins: [i18n],

  data() {
    return {
      items: [],
      categories: [],
      loading: false,
    };
  },

  methods: {
    openForm() {
      this.$refs.form.init();
    },

    openFormFilled(item) {
      this.$refs.form.init(item);
    },

    async loadCategories() {
      this.categories = await indexManagementCategories();
    },

    async handleSubmit(form) {
      this.$refs.form.loading = true;
      this.$refs.form.setErrors();

      try {
        const newItem = await store(form);
        this.$refs.form.close();

        this.updateOrAddItem(newItem);
      } catch (error) {
        console.error(error);
        if (error.response?.data?.errors) {
          this.$refs.form.setErrors(error.response.data.errors);
        }
      } finally {
        this.$refs.form.loading = false;
      }
    },

    async handleDelete(item) {
      if (
        !confirm(
          `Tem certeza que deseja excluir o mapeamento da categoria ${item.category_description}?`
        )
      ) {
        return;
      }

      await destroy(item.id);
      this.items = this.items.filter((i) => i.id !== item.id);
    },

    updateOrAddItem(newItem) {
      if (this.items.find((item) => item.id === newItem.id)) {
        this.items = this.items.map((item) => {
          if (item.id === newItem.id) {
            return newItem;
          }

          return item;
        });

        return;
      }

      this.items.push(newItem);
    },

    async loadItems() {
      this.loading = true;

      try {
        this.items = await index();
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },

  created() {
    this.loadCategories();
    this.loadItems();
  },
};
</script>
