<template>
  <v-dialog v-model="dialog" width="600" persistent>
    <v-card :disabled="loading">
      <v-form @submit.prevent="submit">
        <v-card-title>
          {{ title }}
          <v-spacer></v-spacer>
          <v-btn @click="close()" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-autocomplete
            v-model="form.category_id"
            :error-messages="errors.category_id"
            label="Categoria HCM"
            filled
            dense
            :items="categories"
            item-text="description"
            item-value="id"
            autofocus
            clearable
          ></v-autocomplete>
          <v-row>
            <v-col>
              <v-text-field
                v-model="form.category_value"
                :error-messages="errors.category_value"
                label="Código Categoria EPS"
                filled
                dense
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="form.subcategory_value"
                :error-messages="errors.subcategory_value"
                label="Código Subcategoria EPS"
                filled
                dense
              >
                filled dense
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="close()" text> Cancelar </v-btn>
          <v-btn type="submit" color="primary" :loading="loading">
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    title: String,
    categories: Array,
  },

  data() {
    return {
      loading: false,
      dialog: false,
      errors: {},
      form: {
        category_id: null,
        category_value: null,
        subcategory_value: null,
      },
    };
  },

  methods: {
    reset(initial = null) {
      if (!initial) {
        this.form = {
          category_id: null,
          category_value: null,
          subcategory_value: null,
        };
      } else {
        this.form = {
          ...initial,
        };
      }

      this.setErrors();
    },

    init(initial = null) {
      this.reset(initial);
      this.dialog = true;
    },

    close() {
      this.dialog = false;
    },

    setErrors(errors = {}) {
      this.errors = errors;
    },

    submit() {
      this.$emit("submit", { ...this.form });
    },
  },
};
</script>
